import emailIcon from '../assets/email_icon.png'
import githubIcon from '../assets/github_icon.png'
import linkedinIcon from '../assets/linkedin_icon.png'

const SOCIAL_PROFILES = [
    {
        id: 1,
        link: 'mailto:xabi1309@gmail.com',
        image: emailIcon
    },
    {
        id: 2,
        link: 'https://github.com/xvega/',
        image: githubIcon
    },
    {
        id: 3,
        link: 'https://www.linkedin.com/in/xavier-vega/',
        image: linkedinIcon
    },
];

export default SOCIAL_PROFILES;